import React, { useState } from 'react';
import { ThemeProvider, withAuthenticator, View, Tabs, TabItem, Text, SelectField, Flex, Icon } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { BiServer } from 'react-icons/bi';

import './App.css';
import { Nav } from './components/Nav';
import { ReactComponent as FMLogo } from './images/FileManager_logo.svg';
import { ReactComponent as CIBCLogo } from './images/CIBC_logo_2021.svg';
import { FileJobList } from './components/FileJobList';
import { Categories } from './models';
import { Environment } from './models';
import { theme } from './theme';

const App = () => {
  const [env, setEnv] = useState(Environment.UAT);

  return (
    <View className="App" marginTop="32px">
      <Nav />

      <View margin="32px auto">
        <CIBCLogo />
      </View>

      <Flex maxWidth="160px" margin="32px auto" fontSize="24px" alignItems="center">
        <Icon as={BiServer} />
        <SelectField label="Environment" labelHidden={true} value={env} onChange={e => setEnv(e.target.value as Environment)}>
          {
            Object.values(Environment).map(env => (
              <option value={env}>{env}</option>
            ))
          }
        </SelectField>
      </Flex>

      <Tabs minWidth="800px">
        {
          Object.values(Categories).map(c => (
            <TabItem title={c.replaceAll('_', ' ')} key={`categories-tab-${c}`}>
              <FileJobList env={env} category={Categories[c]} />
            </TabItem>
          ))
        }
      </Tabs>

      <Text textAlign="center" fontSize="12px" margin="32px auto">
        ©{new Date().getFullYear()} CapIntel Inc.
      </Text>
    </View>
  );
}

const components = {
  Header() {
    return (
      <View marginTop="160px">
      </View>
    );
  },
  SignIn: {
    Header() {
      return (
        <View textAlign="center" marginTop="32px" marginBottom="-24px">
          <FMLogo width="220px" />
        </View>
      )
    }
  }
}

const ThemedApp = () => (
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
)

export default withAuthenticator(ThemedApp, { hideSignUp: true, variation: 'default', components });
