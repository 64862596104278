import { Button, Flex, Icon, View, useAuthenticator } from "@aws-amplify/ui-react";
import { ReactComponent as FMLogo } from '../images/FileManager_logo.svg';
import { BiLogOutCircle } from 'react-icons/bi';

export const Nav = () => {
  const { signOut } = useAuthenticator(context => [context.signOut]);

  return (
    <Flex alignItems="center">
      <FMLogo width="220px" />
      <View grow="1" />
      <Button onClick={signOut}>
        <Icon as={BiLogOutCircle} marginRight="6px" />
        Sign Out
      </Button>
    </Flex>
  );
}