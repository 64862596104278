import React, { useEffect, useState } from "react";
import { useTheme, Flex, Text, Heading, Loader, View, Badge, Icon } from "@aws-amplify/ui-react";
import { DataStore, SortDirection } from "aws-amplify";
import { BiFile, BiFileFind } from 'react-icons/bi';

import { Environment, FileJob, FileJobStatus } from "../models";
import { Categories } from "../models";
import { StatusBadge } from "./StatusBadge";


type FileJobCardProps = {
  env: Environment;
  type: string;
  category: Categories;
  lastUpdate: Date;
}

export const FileJobCard = ({ env, type, category, lastUpdate }: FileJobCardProps) => {
  const [job, setJob] = useState<FileJob>();
  const [isEmpty, setIsEmpty] = useState(false);

  const { tokens } = useTheme();

  useEffect(() => {
    const fetchData = async () => {

      const results = (await DataStore.query(FileJob,
        c => (
          c.and(c => [
            c.category.eq(category),
            c.type.eq(type),
            c.env.eq(env)
          ])
        ),
        {
          sort: s => s.createdAt(SortDirection.DESCENDING),
          limit: 1,
        }
      ));

      if (!results.length) {
        setIsEmpty(true);
      }

      setJob(results.pop());
    };

    fetchData();
  }, [type, category, lastUpdate, env]);

  if (isEmpty) {
    return (
      <Flex grow={1} alignItems="center" padding="16px 16px 16px 0">
        <Icon as={BiFileFind} fontSize="24px" color={tokens.colors.brand.primary[80]} />
        <View grow={1} textAlign="left">
          <Heading level={3} fontSize="24px">
            {type}
          </Heading>
        </View>
        <Badge>NO FILE</Badge>
      </Flex>
    )
  }

  if (!job) {
    return (
      <View grow={1}>
        <Loader />
      </View>
    )
  }

  const createdAt = job.createdAt && new Date(job.createdAt);
  const formattedCreatedAt = createdAt && `${createdAt.toLocaleDateString()} - ${createdAt.toLocaleTimeString()}`

  return (
    <Flex grow={1} alignItems="center" padding="16px 16px 16px 0">
      <Icon as={BiFile} fontSize="24px" color={tokens.colors.brand.primary[80]} />
      <View grow={1} textAlign="left">
        <Heading level={3} fontSize="24px">
          {job.type}
        </Heading>
        <View>
          <Text as="strong" fontWeight="bold">Uploaded:</Text> {formattedCreatedAt}
        </View>
        <View>
          {job.filename}
        </View>
      </View>
      <StatusBadge status={FileJobStatus[job.status]} />
    </Flex>
  )
};