// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Environment = {
  "UAT": "UAT",
  "PROD": "PROD"
};

const Categories = {
  "ICS": "ICS",
  "CPIC": "CPIC",
  "PPS": "PPS",
  "IMPERIAL_POOLS": "IMPERIAL_POOLS"
};

const FileJobStatus = {
  "OK": "OK",
  "ERROR": "ERROR",
  "PROCESSING": "PROCESSING"
};

const { FileJob } = initSchema(schema);

export {
  FileJob,
  Environment,
  Categories,
  FileJobStatus
};