import { Badge, BadgeVariations } from "@aws-amplify/ui-react";
import { FileJobStatus } from "../models";


type StatusBadgeProps = {
  status: FileJobStatus;
};

const COLORS: Record<FileJobStatus, BadgeVariations> = {
  [FileJobStatus.PROCESSING]: 'info',
  [FileJobStatus.OK]: 'success',
  [FileJobStatus.ERROR]: 'error',
}

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  return (
    <Badge variation={COLORS[status]}>
      {status}
    </Badge>
  );
};