export const schema = {
    "models": {
        "FileJob": {
            "name": "FileJob",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": {
                        "enum": "Categories"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "filename": {
                    "name": "filename",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "s3Key": {
                    "name": "s3Key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "FileJobStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "hash": {
                    "name": "hash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "env": {
                    "name": "env",
                    "isArray": false,
                    "type": {
                        "enum": "Environment"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "FileJobs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "Environment": {
            "name": "Environment",
            "values": [
                "UAT",
                "PROD"
            ]
        },
        "Categories": {
            "name": "Categories",
            "values": [
                "ICS",
                "CPIC",
                "PPS",
                "IMPERIAL_POOLS"
            ]
        },
        "FileJobStatus": {
            "name": "FileJobStatus",
            "values": [
                "OK",
                "ERROR",
                "PROCESSING"
            ]
        }
    },
    "nonModels": {},
    "codegenVersion": "3.4.4",
    "version": "fbbeea5f30d31bf9ce5441bee2c33fda"
};