import { Alert, Collection, Expander, ExpanderItem } from "@aws-amplify/ui-react";
import { StorageManager } from '@aws-amplify/ui-react-storage';
import React, { useState } from "react";

import { DataStore } from "aws-amplify";
import { Environment, FileJob, FileJobStatus } from "../models";
import { Categories } from "../models";
import { ProcessFileParams } from "@aws-amplify/ui-react-storage/dist/types/components/StorageManager/types";
import { FileJobCard } from "./FileJobCard";


type FilesListProps = {
  env: Environment;
  category: Categories;
};

const TYPES = {
  [Categories.ICS]: ['Mapping', 'Characteristics', 'Ongoing Performance'],
  [Categories.CPIC]: ['Characteristics', 'Ongoing Performance'],
  [Categories.PPS]: ['Allocations', 'Performance'],
  [Categories.IMPERIAL_POOLS]: ['Characteristics', 'Ongoing Performance'],
};

const getFileHash = async (file: File) => {
  const buffer = await file.arrayBuffer();
  const hashBuffer = await window.crypto.subtle.digest('SHA-1', buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray.map((a) => a.toString(16).padStart(2, '0')).join('');
  return hash;
}

export const FileJobList = ({ env, category }: FilesListProps) => {

  const [lastUpdate, setLastUpdate] = useState(new Date());

  const processFile = async (params: ProcessFileParams, type: string) => {
    const filename = params.file.name;
    const s3Key = `${env}/${category}/${type}/${filename}`;
    const status = FileJobStatus.PROCESSING;
    const hash = await getFileHash(params.file);

    await DataStore.save(new FileJob({ env, type, category, filename, s3Key, status, hash }));

    setLastUpdate(new Date());

    return { ...params, key: s3Key };
  };
  
  return (
    <Collection
      items={TYPES[category]}
      type="list"
      direction="column"
      gap="32px"
      wrap="nowrap"
      margin="32px 0"
    >
      {
        type => (
          <Expander
            isCollapsible={true}
          >
            <ExpanderItem
              title={<FileJobCard env={env} type={type} category={category} lastUpdate={lastUpdate} />}
              value={type}
            >
              {
                env === Environment.PROD && (
                  <Alert variation="warning" marginBottom="16px">
                    You are about to upload and modify data in the <strong>Production</strong> environment
                  </Alert>
                )
              }
              <StorageManager
                acceptedFileTypes={['.xlsx']}
                accessLevel="public"
                maxFileCount={1}
                processFile={(params) => { return processFile(params, type) }}
              />
            </ExpanderItem>
          </Expander>
        )
      }
    </Collection>
  );
}