import { Theme } from "@aws-amplify/ui-react";

export const theme: Theme = {
  name: 'expander-theme',
  tokens: {
    components: {
      expander: {
        trigger: {
          _hover: {
            backgroundColor: { value: '{colors.teal.10}' },
          },
        },
      },
    },
  },
};
