/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_appsync_graphqlEndpoint": "https://45rwlcquivf63bkmjrtwlmpm2m.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-4luxksw325bvxkwa73uq25ogqa",
    "aws_cognito_identity_pool_id": "ca-central-1:99bee219-c49f-4c2e-9565-3da22559bb43",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_I8n0nekzP",
    "aws_user_pools_web_client_id": "4a9f36hjbe997ksut3kmu552ar",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "filemanager-storage-fe31f2f4172627-prod",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
